import Smartlook from 'smartlook-client';
import { PUBLIC_SMARTLOOK_KEY } from '$env/static/public'

export const initSmartlook = () => {
    Smartlook.init(PUBLIC_SMARTLOOK_KEY, { 
        region: 'eu', 
        advancedNetwork: {
            alllowedUrls: [/.*/],
        }
    });
}

export const smartlookClient = Smartlook;
